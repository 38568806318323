import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { getUserData } from "../../controller/services/hospital/profile";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import ContactIcon from "../../assets/icons/contact.svg";
export default function ViewUser() {
  const [userData, setUserData] = useState(null);
  const params = useParams();
  console.log(params, "fdsfsdf");

  useEffect(() => {
    if (params.id) {
      getViewData();
    }
  }, [params.id]);
  const getViewData = () => {
    getUserData({ id: params.id })
      .then((resp) => {
        setUserData(resp.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <Box>
      <Grid container textAlign={"left"} padding={"40px"} margin={0}>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={12}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          item
        >
          <img
            src={userData?.hospitalLogo ? userData?.hospitalLogo : ContactIcon}
            alt="logo"
            width={150}
            height={150}
            style={{ borderRadius: "50%" }}
          />
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            First name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.firstName || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Last name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.lastName || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            E-mail
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.email || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Phone Number
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.phone || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Hospital name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.hospitalName || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Description
          </Typography>
          <Typography fontSize={"18px"} color="grey" overflow={"hidden"}>
            {userData?.description || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Address
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.address || "-"}, {userData?.city}, {userData?.state} -{" "}
            {userData?.zip}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Website
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.socialMediaLink?.website || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
            paddingRight: "15px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Linkedin
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.socialMediaLink?.linkedin || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
            // paddingLeft:'15px'
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Instagram
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.socialMediaLink?.instagram || "-"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
