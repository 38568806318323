import axiosInstance from "../api/axios";

export const FileUpload = async (payload) => {
  const res = await axiosInstance.post("/api/v1/user/uploads", payload);
  return res.data;
};

export const DeleteUploadedFile = async (payload) => {
  const res = await axiosInstance.post("/api/v1/user/uploads/delete", payload);
  return res.data;
};

export const SendSupportQuery = async (payload) => {
  const res = await axiosInstance.post("/api/v1/support/contact", payload);
  return res.data;
};

export const DeleteUser = async () => {
  const res = await axiosInstance.delete("/api/v1/user/");
  return res.data;
};

export const getReportingCounts = async () => {
  const res = await axiosInstance.post(`/api/v1/analytics/`);
  return res.data;
};

export const getReportingData = async (payload) => {
  const res = await axiosInstance.post(
    `/api/v1/analytics/list-hospital-analytics`,
    payload
  );
  return res.data;
};
