import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Signin from "./components/common/auth/signin";
import ForgotPassword from "./components/common/auth/reset-password/forgotPassword";
import { Toaster } from "react-hot-toast";
import VerifyOtp from "./components/common/auth/reset-password/verifyOtp";
import ResetPassword from "./components/common/auth/reset-password/resetPassword";
import PasswordChangeSuccess from "./components/common/auth/reset-password/success";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Dashboard from "./components/dashboard";
import ManageHospitals from "./components/manage-hospitals";
import ManageNurses from "./components/manage-nurses";
import ChangePasswordSettings from "./components/settings/change-password";
import ViewUser from "./components/view-user";
import Profile from "./components/settings/profile";
import ViewNurseUser from "./components/view-nurse-user";
import ManageJobs from "./components/manage-jobs";
import ViewJobDetails from "./components/view-job-post";
import Reporting from "./components/reporting";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/password-changed" element={<PasswordChangeSuccess />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-hospitals"
            element={
              <ProtectedRoute>
                <ManageHospitals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-jobs"
            element={
              <ProtectedRoute>
                <ManageJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-nurses"
            element={
              <ProtectedRoute>
                <ManageNurses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePasswordSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporting"
            element={
              <ProtectedRoute tspColor>
                <Reporting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-hospitals/users/:id"
            element={
              <ProtectedRoute>
                <ViewUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-nurses/users/:id"
            element={
              <ProtectedRoute>
                <ViewNurseUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-jobs/:id"
            element={
              <ProtectedRoute>
                <ViewJobDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "lightgreen",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
