import {Typography, Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ConfirmationIcon from "../../../../assets/icons/confirmation.svg";

export default function PasswordChangeSuccess() {
    const navigate = useNavigate();

    return (
        <Box sx={{
            padding: {
                xs: "30px",
                sm: "0 30px",
                md: "0 70px",
                lg: "0 70px",
                xl: "0 70px",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            height: {
                xs: "auto",
                sm: "auto",
                md: "100vh",
                lg: "100vh",
                xl: "100vh",
            },
        }}>
            <Box
                border={2}
                borderColor={"#38879F"}
                borderRadius={5}
                sx={{
                    position: "relative",
                    width: '100%'
                }}
                display="flex"
                alignItems='center'
                justifyContent="center"
            >
                <Box sx={{
                    padding: {
                        xs: "30px 20px",
                        sm: "45px",
                        md: "45px",
                        lg: "45px",
                        xl: "70px 45px",
                    },
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '70%',
                        lg: '35%',
                        xl: '30%'
                    }
                }}>
                    <img className="app-logo-role" src={ConfirmationIcon} alt="logo"/>
                    <Typography
                        variant="h3"
                        color="#0d2645"
                        fontWeight={600}
                        sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
                    >
                        Password Changed Successfully.
                    </Typography>
                    <Typography
                        variant="h6"
                        color="#8391a0"
                        fontWeight={400}
                        sx={{
                            padding: {
                                xs: '0 0 30px',
                                sm: '10px',
                                md: '10px',
                                lg: '10px',
                                xl: '10px'
                            },
                            fontSize: {
                                xs: '12px',
                                sm: '14px',
                                md: '16px',
                                lg: '16px',
                                xl: '16px'
                            },
                            lineHeight: {
                                xs: "20px",
                                sm: "24px",
                                md: "25px",
                                lg: "25px",
                                xl: "25px"
                            }
                        }}
                    >
                        Please click the button below for login.
                    </Typography>
                    <Button
                        sx={{
                            width: "100%",
                            height: "55px",
                            marginTop: "15px",
                            background: "#38879f",
                            color: "#fff",
                            fontWeight: 600,
                            borderRadius: "10px",
                            "&.Mui-disabled": {
                                background: "#adcdd7",
                                color: "#fff",
                            },
                            "&:hover": {
                                backgroundColor: "#38879f",
                            },
                        }}
                        onClick={() => navigate("/signin")}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
