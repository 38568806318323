import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = flagsSlice.actions;
export default flagsSlice.reducer;
