import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import ContactIcon from "../../../assets/icons/contact.svg";
import toggleMenu from "../../../assets/icons/toggle-menu.svg";
import DownArrow from "../../../assets/icons/down-arrow.svg";
import { useState } from "react";
import { setSelectedHospitalType } from "../../../redux/slices/common_slice";

export default function AppLayout({ children, tspColor }) {
  const userData = useSelector((state) => state.user.userData);
  const isLoading = useSelector((state) => state.flags.isLoading);
  const breadcrumbArr = useSelector((state) => state.common.breadCrumb);
  const selectedType = useSelector(
    (state) => state.common.selectedHospitalType
  );

  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const handleTypeChange = (e) => {
    const value = e.target.value ? e.target.value : "";
    dispatch(setSelectedHospitalType(value));
  };
  return (
    <Grid
      sx={{
        height: {
          xs: "auto",
          sm: "auto",
          md: "auto",
          lg: "100vh",
          xl: "100vh",
        },
        display: "flex",
      }}
    >
      <Grid
        sx={{
          width: {
            xs: "100%",
            sm: "254px",
            md: "254px",
            lg: "254px",
            xl: "254px",
          },
          height: {
            xs: "calc(100vh - 70px)",
            sm: "calc(100vh - 70px)",
            md: "100vh",
            lg: "100vh",
            xl: "100vh",
          },
          position: {
            xs: "fixed",
            sm: "fixed",
            md: "fixed",
            lg: "fixed",
            xl: "fixed",
          },
          left: {
            xs: toggle ? 0 : "-100%",
            sm: toggle ? 0 : "-100%",
            md: 0,
            lg: 0,
            xl: 0,
          },
          transition: "0.4s ease all",
          zIndex: 9,
          background: "#ffffff",
          padding: {
            xs: "0 15px",
            sm: "0 15px",
            md: "0 15px",
            lg: 0,
            xl: 0,
          },
          top: {
            xs: "70px",
            sm: "70px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          },
        }}
      >
        <Sidebar />
      </Grid>
      <Grid
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "calc(100% - 254px)",
            lg: "calc(100% - 254px)",
            xl: "calc(100% - 254px)",
          },
          marginLeft: {
            xs: 0,
            sm: 0,
            md: "254px",
            lg: "254px",
            xl: "254px",
          },
          height: "100vh",
          overflow: "auto",
          borderRadius: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: "50px 0 0 50px",
            xl: "50px 0 0 50px",
          },
          padding: {
            xs: "15px 15px 15px",
            sm: "20px 30px 30px",
            md: "20px 30px 30px",
            lg: "20px 30px 30px",
            xl: "20px 30px 30px",
          },
        }}
        bgcolor={"#eff6f8"}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          marginBottom={"20px"}
          alignItems={"center"}
          sx={{
            position: {
              xs: "fixed",
              sm: "fixed",
              md: "fixed",
              lg: "relative",
              xl: "relative",
            },
            top: 0,
            left: 0,
            height: {
              xs: "70px",
              sm: "70px",
              md: "80px",
              lg: "auto",
              xl: "auto",
            },
            zIndex: 8,
            background: {
              xs: "#fff",
              sm: "#fff",
              md: "#fff",
              lg: "transparent",
              xl: "transparent",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "auto",
              xl: "auto",
            },
            padding: {
              xs: "0 15px",
              sm: "0 15px",
              md: "0 15px",
              lg: 0,
              xl: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              onClick={() => setToggle((prev) => !prev)}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                marginRight: "20px",
                position: "relative",
                top: 0,
                cursor: "pointer",
              }}
            >
              <img src={toggleMenu} alt="" />
            </Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "22px",
                  md: "24px",
                  lg: "26px",
                  xl: "28px",
                },
              }}
              fontWeight={700}
              color="#0D2645"
            >
              {breadcrumbArr?.length > 0 && breadcrumbArr[0]?.name}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {window.location.href.includes("manage-hospitals") && (
              <Select
                name="experienceLevel"
                placeholder="Select"
                value={selectedType}
                renderValue={(value) => {
                  return (
                    <Typography
                      fontSize="13px"
                      fontWeight={600}
                      lineHeight="29px"
                      color="#0d2645"
                    >
                      {value === "pending"
                        ? "Pending"
                        : value === "blocked"
                        ? "Block"
                        : value === "active"
                        ? "Active"
                        : "All"}
                    </Typography>
                  );
                }}
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                MenuProps={{
                  style: {
                    padding: "0 !important",
                  },
                }}
                // value={row?.userStatus || "pending"}
                IconComponent={(props) => {
                  return (
                    <img
                      src={DownArrow}
                      alt="arrow"
                      style={{ marginRight: "5px", top: "11px" }}
                      {...props}
                    />
                  );
                }}
                sx={{
                  padding: "10px 5px 10px 0",
                  background: {
                    xs: "#eff6f8",
                    sm: "#eff6f8",
                    md: "#eff6f8",
                    lg: "#fff",
                    xl: "#fff",
                  },
                  color: "#0d2645",
                  width: "50%",
                  height: "50px",
                  marginRight: "10px",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                    outline: "none",
                  },
                }}
                onChange={handleTypeChange}
              >
                <MenuItem
                  value="all"
                  sx={{
                    height: "25px",
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "29px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "20px 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  All
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="pending"
                  sx={{
                    height: "25px",
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "29px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "20px 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  Pending
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="active"
                  sx={{
                    height: "30px",
                    width: "100%",
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "29px",
                    display: "flex",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "20px 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  Active
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="blocked"
                  sx={{
                    height: "30px",
                    width: "100%",
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "29px",
                    display: "flex",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "20px 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  Block
                </MenuItem>
              </Select>
            )}
            <Button
              sx={{
                marginRight: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                height: {
                  xs: "40px",
                  sm: "40px",
                  md: "50px",
                  lg: "50px",
                  xl: "50px",
                },
                width: {
                  xs: "100px",
                  sm: "120px",
                  md: "150px",
                  lg: "150px",
                  xl: "150px",
                },
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
                fontWeight: 600,
                borderRadius: "8px",
                background: "#38879f",
                textTransform: "none",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={() => {
                window.open(
                  "https://console.firebase.google.com/u/1/project/transparent-care/analytics/app/web:YmZjYjcyMjYtMTE4Yi00MGJhLWI5YTItODgyNjk3Y2FiMjY3/overview",
                  "_blank"
                );
              }}
            >
              Analytics
            </Button>
            <img
              src={
                userData?.profilePicture
                  ? userData?.profilePicture
                  : ContactIcon
              }
              alt="pic"
              width={70}
              height={70}
              className="profile-img"
              style={{ borderRadius: "50%" }}
            />
          </Box>
        </Box>
        <Box
          bgcolor={tspColor ? "transparent" : "#fff"}
          borderRadius={"25px"}
          position="relative"
          sx={{
            marginTop: {
              xs: "70px",
              sm: "70px",
              md: "80px",
              lg: 0,
              xl: 0,
            },
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              xl: "calc(100vh - 145px)",
              lg: "calc(100vh - 145px)",
            },
            overflow: "auto",
          }}
        >
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: 10,
              borderRadius: "50px",
              left: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: "16.5%",
                xl: "16.5%",
              },
              background: "transparent",
            }}
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
