import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUsersList } from "../../controller/services/manage-users";
import ContactIcon from "../../assets/icons/contact.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../redux/slices/common_slice";
import toast from "react-hot-toast";
import { setLoading } from "../../redux/slices/flags_slice";
import NoDataFound from "../common/NoDataFound";
import NoDataIcon from "../../assets/icons/no-data.svg";
const columns = [
  "S. no",
  "First name",
  "Last name",
  "Profile picture",
  "Email",
  "Years of experience",
  "View Resume",
  "View License",
  "View User",
];
export default function ManageNurses() {
  const [page, setPage] = React.useState(0);
  const [usersList, setUsersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getUserList();
    const breadCrumbArr = [{ name: "Manage nurses", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, [page]);
  const getUserList = () => {
    dispatch(setLoading(true));
    const payload = {
      userType: "nurse",
      page: page + 1,
      limit: 10,
      searchKey: "",
    };
    getUsersList(payload)
      .then((resp) => {
        setUsersList(resp.data.list);
        setTotalCount(resp.data.resultCount);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return usersList?.length > 0 ? (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600, height: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  align={"center"}
                  style={{ minWidth: "50px" }}
                  sx={{ fontWeight: 600, minWidth: 150 }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell style={{ minWidth: "50px" }} align="center">
                    {(index == 9 ? page + 1 : page) +
                      `${index == 9 ? "0" : index + 1}`}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.firstName || "-"}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.lastName || "-"}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    <img
                      src={
                        row?.profilePicture ? row?.profilePicture : ContactIcon
                      }
                      alt="logo"
                      width={50}
                      height={50}
                      style={{ borderRadius: "50%" }}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.experience || "-"}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.resume && (
                      <Link to={row?.resume} target="_blank">
                        <img src={EyeIcon} style={{ cursor: "pointer" }} />{" "}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align="center">
                    {row?.license && (
                      <Link to={row?.license} target="_blank">
                        <img src={EyeIcon} style={{ cursor: "pointer" }} />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align="center">
                    <img
                      src={EyeIcon}
                      onClick={() =>
                        navigate(`/manage-nurses/users/${row?._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  ) : (
    <NoDataFound title={"No Nurses Found"} icon={NoDataIcon} />
  );
}
