import { combineReducers } from "@reduxjs/toolkit";
import flags_slice from "./slices/flags_slice";
import user_slice from "./slices/user_slice";
import common_slice from "./slices/common_slice";

const rootReducer = combineReducers({
  flags: flags_slice,
  user: user_slice,
  common: common_slice,
});

export default rootReducer;
