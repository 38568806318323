import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/flags_slice";
import { format } from "date-fns";
import StyledChip from "../common/styled/chip";
import { getJobDetails } from "../../controller/services/manage-jobs";
import { getShiftNames } from "../../utils/helper";

const labelStyles = {
  fontFamily: "Lato",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "21.6px",
  color: "#0D2645",
  padding: "10px 0",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#8b97a6",
  padding: "10px 0",
};
export default function ViewJobDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const [jobData, setJobData] = useState(null);
  useEffect(() => {
    fetchJobDetails();
  }, []);
  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    getJobDetails(params.id)
      .then((resp) => {
        setJobData(resp.data.job);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  if (jobData) {
    return (
      <Box
        sx={{
          padding: {
            xs: "20px 15px",
            sm: "20px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          },
        }}
        maxHeight={"90vh"}
        overflow={"auto"}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            alignItems: "center",
            marginBottom: {
              xs: 0,
              sm: "10px",
              md: 0,
              lg: 0,
              xl: 0,
            },
          }}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              alignItems: "center",
              maxWidth: {
                xs: "100%",
                sm: "70%",
                md: "initial",
                lg: "initial",
                xl: "initial",
              },
            }}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: {
                  xs: "18px",
                  sm: "18px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: 700,
                lineHeight: "28.8px",
                textAlign: "left",
                marginBottom: {
                  xs: "10px",
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
                color: "#0D2645",
                marginRight: {
                  xs: 0,
                  sm: 0,
                  md: "10px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              {jobData?.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "space-between",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "flex-start",
                  xl: "flex-start",
                },
                marginBottom: {
                  xs: "20px",
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            >
              <Chip
                sx={{
                  background: "#ecf3f6",
                  marginRight: {
                    xs: 0,
                    sm: "10px",
                    md: "10px",
                    lg: "10px",
                    xl: "10px",
                  },
                }}
                label={
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "9px",
                          sm: "12px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        },
                        fontWeight: 400,
                        lineHeight: "12px",
                        textAlign: "left",
                        color: "#50647a",
                      }}
                    >
                      Start Date:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "10px",
                          sm: "14px",
                          md: "15px",
                          lg: "16px",
                          xl: "16px",
                        },
                        fontWeight: 500,
                        lineHeight: "19.2px",
                        textAlign: "left",
                        color: "#0D2645",
                      }}
                    >
                      &nbsp;
                      {jobData?.startDate
                        ? format(new Date(jobData?.startDate), "dd MMM, yyyy")
                        : ""}
                    </Typography>
                  </Box>
                }
                variant="filled"
              />
              <Chip
                variant="filled"
                label="Pending"
                sx={{
                  bgcolor: "#F19000",
                  color: "#fff",
                  fontFamily: "Lato",
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  lineHeight: "32px",
                  fontWeight: 700,
                  marginLeft: {
                    xs: 0,
                    sm: 0,
                    md: "10px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Grid container>
          <Grid
            sx={{ padding: "5px 2px" }}
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={2}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Job Offer:"}
              value={`$${
                jobData?.weeklyPay +
                jobData?.houseAllowance +
                jobData?.mealAllowance
              } /wk`}
            />
          </Grid>
          <Grid
            sx={{
              padding: "5px 2px",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                xl: "block",
                lg: "block",
              },
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Shift:"}
              value={jobData?.shifts?.map((shift, idx) => {
                return (
                  getShiftNames(shift) +
                  (jobData?.shifts?.length !== idx + 1 ? ", " : "")
                );
              })}
            />
          </Grid>
          <Grid
            sx={{ padding: "5px 2px" }}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={2}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Duration:"}
              value={`${jobData?.duration} Weeks`}
            />
          </Grid>
          <Grid
            sx={{
              padding: "5px 2px",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                xl: "none",
                lg: "none",
              },
            }}
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Shift:"}
              value={jobData?.shifts?.map((shift, idx) => {
                return (
                  getShiftNames(shift) +
                  (jobData?.shifts?.length !== idx + 1 ? ", " : "")
                );
              })}
            />
          </Grid>
          <Grid
            sx={{ padding: "5px 2px" }}
            xs={6}
            sm={4}
            md={4}
            lg={3}
            xl={2}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Exp. Required:"}
              value={`${jobData?.experience} Years`}
            />
          </Grid>
          <Grid
            sx={{ padding: "5px 2px" }}
            xs={6}
            sm={4}
            md={4}
            lg={3}
            xl={2}
            item
          >
            <StyledChip
              variant="outlined"
              styles={{
                border: "1px solid #d7e7ec",
              }}
              label={"Hired Positions:"}
              value={jobData?.numberOfOpenings}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            margin: {
              xs: "10px 0",
              sm: "10px 0",
              md: "20px 0",
              lg: "20px 0",
              xl: "20px 0",
            },
          }}
        />
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Location</Typography>
          <Typography sx={{ ...valueStyles }}>
            MediCore Hospitals, 3138 Peck Court, Los Angeles, California 90017
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Description</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.description}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles, marginBottom: "10px" }}>
            Paycheck
          </Typography>
          <Box
            border={"1.5px solid #99c1cd"}
            padding={"20px"}
            borderRadius={"20px"}
          >
            <Box
              padding={"0 0 20px 0"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Weekly Earnings
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                ${jobData?.weeklyPay}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </span>
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"20px 0 5px 0"}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Travel Per Diems
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  ${jobData?.houseAllowance + jobData?.mealAllowance}
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </span>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5 0"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Meals
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  ${jobData?.mealAllowance}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5px 0 20px 0"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Housing
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  ${jobData?.houseAllowance}
                </Typography>
              </Box>
              <Divider />
              <Box
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Total Weekly Payment
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  $
                  {jobData?.weeklyPay +
                    jobData?.houseAllowance +
                    jobData?.mealAllowance}
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return <></>;
}
