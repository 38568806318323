export const getAddressObject = (address_components) => {
  let ShouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  let address = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };
  address_components.forEach((component) => {
    for (let shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        address[shouldBe] = component.long_name;
      }
    }
  });
  return address;
};

export const formatBytes = (bytes, decimals = 0) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getShiftNames = (shift) => {
  switch (shift) {
    case "Day shift":
      return "Days";
    case "Night shift":
      return "Nights";
    case "Afternoon shift":
      return "Afternoon";
    case "Evening shift":
      return "Evening";
    case "Rotating shift":
      return "Rotating";
  }
};
