import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ActiveCardIcon from "../../assets/icons/active-card.svg";
import { setLoading } from "../../redux/slices/flags_slice";
import {
  getReportingCounts,
  getReportingData,
} from "../../controller/services/common";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { sub } from "date-fns";
import ExportIcon from "../../assets/icons/export.svg";
import { setBreadCrumb } from "../../redux/slices/common_slice";
import NoDataFound from "../common/NoDataFound";
import NoDataIcon from "../../assets/icons/no-data.svg";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;

const cardsArr = [
  {
    title: "Total Registered Hospitals",
    icon: ActiveCardIcon,
    key: "hospitalCount",
  },
  {
    title: "Total Registered Nurses",
    icon: ActiveCardIcon,
    key: "nurseCount",
  },
  {
    title: "Total Jobs Posted",
    icon: ActiveCardIcon,
    key: "jobPostCount",
  },
];

const columns = [
  "S. no",
  "Hospital Name",
  "Hired Nurse Count",
  "Job Posts Count",
];

export default function Reporting() {
  const dispatch = useDispatch();
  const [counts, setCounts] = useState({});
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    fetchReportingCounts();
    let startDate = sub(new Date(), { months: 6 });
    startDate = dayjs(startDate);
    const endDate = dayjs(new Date());
    setSelectedDates([startDate, endDate]);
    const breadCrumbArr = [{ name: "Reporting", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, []);

  useEffect(() => {
    if (selectedDates?.length == 2) {
      fetchReportingData(selectedDates[0], selectedDates[1]);
    }
  }, [selectedDates?.length, page]);
  const fetchReportingCounts = () => {
    dispatch(setLoading(true));
    getReportingCounts()
      .then((resp) => {
        setCounts(resp.data);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const fetchReportingData = (startDate, endDate) => {
    const payload = {
      page: page + 1,
      limit: 10,
      startDate: startDate?.format("YYYY-MM-DD") + "T00:00:00.000Z",
      endDate: endDate?.format("YYYY-MM-DD") + "T00:00:00.000Z",
    };
    dispatch(setLoading(true));
    getReportingData(payload)
      .then((resp) => {
        setTableData(resp.data?.hospitalData?.list);
        setTotalCount(resp.data.hospitalData?.resultCount);
        console.log(resp, "respofsf");
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const getData = () => {
    let data = [];
    const payload = {
      page: page + 1,
      limit: totalCount,
      startDate: selectedDates[0]?.format("YYYY-MM-DD") + "T00:00:00.000Z",
      endDate: selectedDates[1]?.format("YYYY-MM-DD") + "T00:00:00.000Z",
    };
    dispatch(setLoading(true));
    getReportingData(payload)
      .then((resp) => {
        const clonedColumns = [...columns];
        clonedColumns.splice(0, 1);
        data.push(clonedColumns);
        if (resp.data?.hospitalData?.list?.length > 0) {
          resp.data?.hospitalData?.list.forEach((i) => {
            const filteredArr = Object.values(i).filter(
              (value, idx) => idx != 0
            );
            data.push(filteredArr);
          });
        }

        const ws = XLSX.utils.aoa_to_sheet(data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Transparent-Care Hired Nurse Report from ${selectedDates[0].format(
          "DD MMM YYYY"
        )} to ${selectedDates[1].format("DD MMM YYYY")}.xlsx`;

        document.body.appendChild(link);
        link.click();
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box>
      <Grid container spacing={2}>
        {cardsArr.map((i, idx) => {
          return (
            <Grid xs={12} sm={6} md={6} lg={5} xl={4} item key={i?.key}>
              <Box
                textAlign={"left"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  borderRadius: "25px",
                  background: "#fff",
                  padding: "25px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "38px",
                      fontWeight: 500,
                      lineHeight: "45.6px",
                      letterSpacing: "0.04em",
                      color: "#0D2645",
                    }}
                  >
                    {counts?.[i.key] || 0}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "21.6px",
                      color: "#8592a1",
                      marginTop: "10px",
                    }}
                  >
                    {i.title}
                  </Typography>
                </Box>
                <img src={i.icon} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Grid item md={12}>
        <Box
          bgcolor={"#fff"}
          marginTop={"20px"}
          padding={"20px"}
          borderRadius={"25px"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                lineHeight: "28.8px",
                color: "#0D2645",
                margin: "10px 0 20px 10px",
              }}
            >
              Reporting Dashboard
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <RangePicker
                maxDate={dayjs(new Date())}
                value={selectedDates}
                allowClear={false}
                onChange={(date, datestring) => {
                  const duration = date[1].diff(date[0], "days");
                  console.log(duration, "fsdfsdf");
                  if (duration <= 365) {
                    setSelectedDates(date);
                    if (page == 0) {
                      fetchReportingData(date[0], date[1]);
                    } else {
                      setPage(0);
                    }
                  } else {
                    toast.error("maximum duration can be selected as 1 year");
                  }
                }}
              />
              <img
                src={ExportIcon}
                alt="export to csv"
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={getData}
              />
            </Box>
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            {tableData?.length > 0 ? (
              <>
                <TableContainer sx={{ maxHeight: 370, height: 370 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column}
                            align={"center"}
                            style={{ minWidth: "50px" }}
                            sx={{ fontWeight: 600, minWidth: 150 }}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            <TableCell
                              style={{ minWidth: "50px" }}
                              align="center"
                            >
                              {(index == 9 ? page + 1 : page) +
                                `${index == 9 ? "0" : index + 1}`}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: "50px" }}
                              align={"center"}
                            >
                              {row?.hospitalName}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: "50px" }}
                              align={"center"}
                            >
                              {row?.hiredNurseCount}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: "50px" }}
                              align={"center"}
                            >
                              {row?.jobPostCount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={totalCount}
                  rowsPerPage={"10"}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </>
            ) : (
              <NoDataFound
                title={"No Data Found"}
                icon={NoDataIcon}
                height={"50vh"}
              />
            )}
          </Paper>
        </Box>
      </Grid>
    </Box>
  );
}
