import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getProfile } from "../../../controller/services/hospital/profile";
import toast from "react-hot-toast";
import ContactIcon from "../../../assets/icons/contact.svg";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../redux/slices/common_slice";
export default function Profile() {
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getProfileData();
    const breadCrumbArr = [{ name: "Profile", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, []);
  const getProfileData = () => {
    getProfile()
      .then((res) => {
        setUserData(res.data?.user);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <Box>
      <Grid
        container
        textAlign={"left"}
        padding={"40px"}
        margin={0}
      >
        <Grid md={12} xs={12} sm={12} lg={12} xl={12} sx={{marginBottom:'30px'}} item>
          <img
            src={
              userData?.profilePicture ? userData?.profilePicture : ContactIcon
            }
            alt="logo"
            width={150}
            height={150}
            className={"profile-img-inner"}
            style={{ borderRadius: "50%" }}
          />
        </Grid>
        <Grid xs={10} sx={{marginBottom:'30px'}} sm={6} md={6} xl={6} lg={6} item>
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            First name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.firstName || "-"}
          </Typography>
        </Grid>
        <Grid xs={10} sx={{marginBottom:'30px'}} sm={6} md={6} xl={6} lg={6} item>
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Last name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.lastName || "-"}
          </Typography>
        </Grid>
        <Grid xs={10} sm={6} md={6} xl={6} lg={6} item>
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            E-mail
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.email || "-"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
