import { createTheme } from "@mui/material";
import DownArrow from "../assets/icons/down-arrow.svg";
export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Lato, sans-serif",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Lato, sans-serif",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: "Lato, sans-serif",
        },
        input: {
          fontFamily: "Lato, sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "21.6px",
          color: "#0d2645",
          marginBottom: "20px",
          fontFamily: "Lato, sans-serif",
          textAlign: "left",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        },
      },
      defaultProps: {
        IconComponent: (props) => {
          return (
            <img
              src={DownArrow}
              alt="arrow"
              style={{ marginRight: "10px", top: "13px" }}
              {...props}
            />
          );
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0D2645",
    },
  },
});
