import { Box, Chip, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getUserData } from "../../controller/services/hospital/profile";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import ContactIcon from "../../assets/icons/contact.svg";
import { Document, Page, pdfjs } from "react-pdf";
import "./index.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewNurseUser() {
  const [userData, setUserData] = useState(null);
  const params = useParams();
  console.log(params, "fdsfsdf");

  useEffect(() => {
    if (params.id) {
      getViewData();
    }
  }, [params.id]);
  const getViewData = () => {
    getUserData({ id: params.id })
      .then((resp) => {
        setUserData(resp.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <Box overflow={"auto"} sx={{ overflowX: "hidden" }}>
      <Grid container textAlign={"left"} padding={"40px"} margin={0}>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={12}
          xs={12}
          sm={12}
          item
        >
          <img
            src={
              userData?.profilePicture ? userData?.profilePicture : ContactIcon
            }
            alt="logo"
            width={150}
            height={150}
            style={{ borderRadius: "50%" }}
          />
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            First name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.firstName || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Last name
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.lastName || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            E-mail
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.email || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Phone Number
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.phone || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Bio
          </Typography>
          <Typography
            fontSize={"18px"}
            color="grey"
            overflow={"hidden"}
            sx={{
              textOverflow: "ellipsis",
              width: "80%",
            }}
          >
            {userData?.bio || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={4}
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Experience
          </Typography>
          <Typography fontSize={"18px"} color="grey">
            {userData?.experience || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={12}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Experience Level
          </Typography>
          <Typography
            fontSize={"18px"}
            color="grey"
            textTransform={"capitalize"}
          >
            {userData?.experienceLevel || "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
            paddingRight: "15px",
          }}
          md={5}
          xs={12}
          sm={6}
          lg={5}
          xl={5}
          item
        >
          <Typography
            fontSize={"15px"}
            fontWeight={600}
            color="#0D2645"
            marginBottom={"20px"}
          >
            Resume
          </Typography>
          <Link to={userData?.resume} target="_blank">
            <Document
              file={userData?.resume}
              options={{ workerSrc: "/pdf.worker.js" }}
              onSourceError={(err) => console.log(err)}
              onSourceSuccess={() => console.log("SUCCESS")}
              //   onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={() => console.log("ERR")}
            >
              <Page
                pageNumber={1}
                scale={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
          </Link>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
            paddingLeft: "15px",
          }}
          md={5}
          xs={12}
          sm={6}
          lg={5}
          xl={5}
          item
        >
          <Typography
            fontSize={"15px"}
            fontWeight={600}
            color="#0D2645"
            marginBottom={"20px"}
          >
            License
          </Typography>
          <Link to={userData?.license} target="_blank">
            <Document
              file={userData?.license}
              options={{ workerSrc: "/pdf.worker.js" }}
              onSourceError={(err) => console.log(err)}
              onSourceSuccess={() => console.log("SUCCESS")}
              //   onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={() => console.log("ERR")}
            >
              <Page
                pageNumber={1}
                scale={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
          </Link>
        </Grid>
        <Grid
          sx={{
            marginBottom: "30px",
          }}
          md={6}
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          item
        >
          <Typography fontSize={"15px"} fontWeight={600} color="#0D2645">
            Licensed States
          </Typography>
          {userData?.licensedStates?.length > 0 ? (
            userData?.licensedStates?.map((i, idx) => {
              return (
                <Chip
                  key={i.name}
                  sx={{
                    bgcolor: "#38879f",
                    margin: "10px",
                    marginLeft: idx == 0 ? 0 : "10px",
                    padding: "10px 5px",
                    borderRadius: "10px",
                  }}
                  label={
                    <Box>
                      <Typography color="#fff">{i.name}</Typography>
                    </Box>
                  }
                />
              );
            })
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
