import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUsersList } from "../../controller/services/manage-users";
import ContactIcon from "../../assets/icons/contact.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import DownArrow from "../../assets/icons/down-arrow.svg";
import { useNavigate } from "react-router-dom";
import { updateUserStatus } from "../../controller/services/hospital/profile";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "../../redux/slices/common_slice";
import { setLoading } from "../../redux/slices/flags_slice";
import "./index.css";
import NoDataFound from "../common/NoDataFound";
import NoDataIcon from "../../assets/icons/no-data.svg";

const columns = [
  "S. no",
  "Name",
  "Phone",
  "Email",
  "Hospital",
  "City",
  "Status",
  "View",
];
export default function ManageHospitals() {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const [usersList, setUsersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const selectedType = useSelector(
    (state) => state.common.selectedHospitalType
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getUserList();
    const breadCrumbArr = [{ name: "Manage hospitals", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, [page]);

  useEffect(() => {
    if (page != 0) {
      setPage(0);
    } else {
      getUserList();
    }
  }, [selectedType]);
  const getUserList = () => {
    dispatch(setLoading(true));
    const payload = {
      userType: "hospital",
      page: page + 1,
      limit: 10,
      searchKey: "",
      status: selectedType != "all" ? selectedType : "",
    };
    getUsersList(payload)
      .then((resp) => {
        setUsersList(resp.data.list);
        setTotalCount(resp.data.resultCount);
      })
      .catch((Err) => {
        console.log(Err, "fsdf");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleStatusChange = (ev, id) => {
    dispatch(setLoading(true));
    const payload = {
      id,
      status: ev.target.value,
    };
    updateUserStatus(payload)
      .then((resp) => {
        toast.success(resp.message);
        if (page != 0) {
          setPage(0);
        } else {
          getUserList();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return usersList?.length > 0 ? (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <TableContainer sx={{ maxHeight: 600, height: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  align={"center"}
                  style={{ minWidth: "50px" }}
                  sx={{ fontWeight: 600, minWidth: 150 }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell style={{ minWidth: "50px" }} align="center">
                    {(index == 9 ? page + 1 : page) +
                      `${index == 9 ? "0" : index + 1}`}
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "50px",
                      maxWidth: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align={"center"}
                  >
                    {row?.firstName + row?.lastName}
                  </TableCell>
                  {/* <TableCell>{row?.lastName}</TableCell> */}
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {((row?.countryCode && "+" + row?.countryCode) || "") +
                      (row?.phone ? "-" + row?.phone || "" : "")}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.email}
                  </TableCell>
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    <Box display={"flex"} alignItems={"center"}>
                      {row?.hospitalName && row?.hospitalLogo && (
                        <>
                          <img
                            src={
                              row?.hospitalLogo
                                ? row?.hospitalLogo
                                : ContactIcon
                            }
                            alt="logo"
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%" }}
                          />
                          <Typography
                            marginLeft={"10px"}
                            fontSize={13}
                            sx={{
                              minWidth: "50px",
                              maxWidth: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row?.hospitalName}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  {/* <TableCell align={"center"}>{row?.description}</TableCell> */}
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    {row?.city}
                  </TableCell>
                  {/* <TableCell>{row?.state}</TableCell> */}
                  {/* <TableCell>{row?.zip}</TableCell> */}
                  {/* <TableCell>{row?.description}</TableCell> */}
                  {/* <TableCell>{row?.description}</TableCell> */}
                  <TableCell style={{ minWidth: "50px" }} align={"center"}>
                    <Select
                      name="experienceLevel"
                      placeholder="Select your level of care experience"
                      renderValue={(value) => (
                        <Typography
                          fontSize="13px"
                          fontWeight={600}
                          lineHeight="29px"
                          color="#0d2645"
                        >
                          {value === "pending"
                            ? "Pending"
                            : value === "blocked"
                            ? "Block"
                            : "Active"}
                        </Typography>
                      )}
                      inputProps={{
                        style: {
                          padding: "10px",
                        },
                      }}
                      MenuProps={{
                        style: {
                          padding: "0 !important",
                        },
                      }}
                      value={row?.userStatus || "pending"}
                      IconComponent={(props) => {
                        return (
                          <img
                            src={DownArrow}
                            alt="arrow"
                            style={{ marginRight: "5px", top: "3px" }}
                            {...props}
                          />
                        );
                      }}
                      sx={{
                        padding: "10px 5px 10px 0",
                        background: "#eff6f8",
                        color: "#0d2645",
                        width: "100%",
                        height: "30px",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:after": {
                          borderBottom: "none",
                        },
                        "&:before": {
                          borderBottom: "none",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                          borderBottom: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                          outline: "none",
                        },
                      }}
                      onChange={(e) => handleStatusChange(e, row?._id)}
                    >
                      <MenuItem
                        disabled
                        value="pending"
                        sx={{
                          height: "25px",
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "29px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "#0d2645",
                          padding: "20px 8px",
                          "&.Mui-selected": {
                            background: "none",
                          },
                        }}
                      >
                        Pending
                      </MenuItem>
                      <Divider
                        sx={{
                          marginTop: "0 !important",
                          marginBottom: "0 !important",
                        }}
                      />
                      <MenuItem
                        value="approve"
                        sx={{
                          height: "30px",
                          width: "100%",
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "29px",
                          display: "flex",
                          alignItems: "center",
                          color: "#0d2645",
                          padding: "20px 8px",
                          "&.Mui-selected": {
                            background: "none",
                          },
                        }}
                      >
                        Active
                      </MenuItem>
                      <Divider
                        sx={{
                          marginTop: "0 !important",
                          marginBottom: "0 !important",
                        }}
                      />
                      <MenuItem
                        value="decline"
                        sx={{
                          height: "30px",
                          width: "100%",
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "29px",
                          display: "flex",
                          alignItems: "center",
                          color: "#0d2645",
                          padding: "20px 8px",
                          "&.Mui-selected": {
                            background: "none",
                          },
                        }}
                      >
                        Block
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align={"center"} style={{ minWidth: "50px" }}>
                    {" "}
                    <img
                      src={EyeIcon}
                      onClick={() =>
                        navigate(`/manage-hospitals/users/${row?._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  ) : (
    <NoDataFound title={"No Hospitals Found"} icon={NoDataIcon} />
  );
}
