import axiosInstance from "../api/axios";

export const getJobList = async (payload) => {
  const res = await axiosInstance.post("/api/v1/admin/list-job-post", payload);
  return res.data;
};

export const updateJobStatus = async (payload) => {
  const res = await axiosInstance.post(
    "/api/v1/admin/change-job-post-status",
    payload
  );
  return res.data;
};

export const getJobDetails = async (id) => {
  const res = await axiosInstance.get(`/api/v1/admin/view-job-post/${id}`);
  return res.data;
};
