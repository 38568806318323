import { Box, Grid, Typography } from "@mui/material";
import AppLogo from "../../assets/icons/app-logo.svg";

export default function Layout({ children, isSignIn }) {
  return (
    <Grid
      sx={{
        height: {
          xs: "100vh",
          sm: "100vh",
          md: "100vh",
          xl: "auto",
          lg: "auto",
        },
        alignItems: {
          xs: "center",
          sm: "center",
          md: "center",
        },
        justifyContent: {
          sm: "center",
        },
      }}
      container
      item
    >
      <Grid
        md={6}
        sx={{
          padding: {
            xs: "4.5% 12%",
            sm: "4.5% 12%",
            md: "3% 6%",
            lg: "1% 12%",
            xl: "1% 12%",
          },
        }}
      >
        <Box>
          <Typography
            variant="h3"
            color="#0d2645"
            fontSize={26}
            fontWeight={600}
            lineHeight={"31.2px"}
            margin={"20px 0 10px"}
          >
            Get Started!
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontSize={16}
            fontWeight={400}
            lineHeight={"25px"}
            margin={"10px"}
          >
            Sign In to your account
          </Typography>
        </Box>
        {children}
      </Grid>
      <Grid
        sx={{
          background: "#eff6f8",
          height: "100vh",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
        md={6}
      >
        {/* <img
          src={BannerImg}
          alt="banner image"
          style={{ height: "99.5vh", width: "100%",objectFit:'cover' }}
        /> */}
        <img className="app-logo-role" src={AppLogo} alt="logo" />
      </Grid>
    </Grid>
  );
}
