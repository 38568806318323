import { Navigate } from "react-router-dom";
import AppLayout from "./app-layout/AppLayout";

const ProtectedRoute = ({ children, tspColor }) => {
  const accessToken = JSON.parse(localStorage.getItem("userData"))?.token;
  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return <AppLayout tspColor={tspColor}>{children}</AppLayout>;
};

export default ProtectedRoute;
