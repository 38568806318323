import { Box, Chip, Typography } from "@mui/material";

export default function StyledChip(props) {
  return (
    <Chip
        className="custom-chip"
      sx={{
        ...props.styles,
        height: {
          xs: "auto",
          sm: "auto",
          md: "35px",
          lg: "35px",
          xl: "35px",
        },
        width: "100%",
        padding: props.styles.padding
          ? props.styles.padding
          : {
              xs: "5px",
              sm: "5px",
              md: "5px 0",
              lg: "5px 0",
              xl: "5px 0",
            },
      }}
      variant={props.variant || "outlined"}
      label={
        <Box  sx={{
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            width: "100%",
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "center",
              lg: "center",
              xl: "center",
            },
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "center",
              lg: "center",
              xl: "center",
            },
          }}
          display={"flex"}>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "12px",
                lg: "12px",
                xl: "12px",
              },
              fontWeight: 400,
              lineHeight: "14.4px",
              textAlign: "left",
              color: "#0D2645",
            }}
          >
            {props.label}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "15px",
                lg: "15px",
                xl: "15px",
              },
              fontWeight: 700,
              lineHeight: "19.2px",
              color: "#0D2645",
              paddingLeft: {
                xs: 0,
                sm: 0,
                md: "5px",
                lg: "5px",
                xl: "5px",
              },
            }}
          >
            {props.value}
          </Typography>
        </Box>
      }
    />
  );
}
